.about a {
    text-decoration: none;
    color: #222;
}

.about .icon-box {
    display: flex;
    gap: 20px;
}

.about .icon-box .icon {
    border: 1px solid #222;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.about .icon-box i {
    font-size: 1.6rem;
    padding: 0.5em;
}

.aboutArticleOne {
    background-color: rgba(18, 174, 224, 0.55);
    padding: 2em 2em;
}

.aboutArticleTwo {
    background-color: rgb(234, 98, 76, 0.5);
    padding: 2em 2em;
}