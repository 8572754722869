/* flex bg-[#FFFBDB] p-10 h-[45rem] text-black  */

.slider {
    display: flex;
    color: #FFFFFF;
    padding: 3em;
    /* background-image: url(../images/home/Home_BG_2.jpg); */
    object-fit: cover;
    /* background-position: center; */
    background-repeat: no-repeat;
    overflow: hidden;
    justify-content: space-between;
    position: relative;
}

.innerSlider {
    width: fit-content;
    width: 50%;
}

.innerSliderTwo {
    width: fit-content;
    margin-left: 2.5rem;
    margin-top: 5rem;
}

.innerSlider h1 {
    line-height: 1;
    margin-bottom: 2rem;
}

.innerSliderTwo h1 {
    line-height: 1;
    margin-top: 5rem;
    margin-bottom: 2rem;
}

.heroSliderDescription {
    font-weight: 500;
    width: 60%;
    color: #FFF;
}

.heroRight {
    width: 40%;
}


.sliderContainer .sliderImageContainer {
    margin-left: 1em;
    padding: 0.3em;
}

.sliderImage {
    padding: 0.5em;
    background-color: #FFF;
}

#emojiOne {
    user-select: none;
    z-index: 10;
    position: absolute;
    right: 40%;
    top: 10%;
    animation: emojiOne 3s infinite;

}


@keyframes emojiOne {
    0% {
        right: 40%;
        top: 10%;
    }

    50% {
        right: 41%;
        top: 15%;
    }

    100% {

        right: 40%;
        top: 10%;

    }
}


#emojiTwo {
    user-select: none;
    width: 100px;
    height: 100px;
    z-index: 10;
    position: absolute;
    right: 50%;
    top: 35%;
    animation: emojiTwo 3s infinite;
    animation-delay: 2s;
}


@keyframes emojiTwo {
    0% {
        right: 50%;
        top: 35%;
        ;
    }

    50% {
        right: 50%;
        top: 30%;
    }

    100% {

        right: 50%;
        top: 35%;

    }
}

#emojiThree {
    user-select: none;
    z-index: 10;
    position: absolute;
    right: 3%;
    top: 10%;
    animation: emojiThree 3s infinite;
}


@keyframes emojiThree {
    0% {
        right: 3%;
        top: 10%;
        transform: matrix3d(1);
    }

    50% {
        right: 1%;
        top: 10%;
        transform: matrix3d(3);
    }

    100% {

        right: 3%;
        top: 10%;
        transform: matrix3d(1);
    }
}





/* ================start background animation====================== */


.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    animation: animate 25s linear infinite;
    bottom: -150px;
    overflow: hidden;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

/* ================Start background animation====================== */

@media (max-width: 786px) {
    .slider {
        display: flex;
        flex-direction: column;
        padding: 1em;
        gap: 1em;
        background-size: cover;
    }

    .heroSliderDescription {
        font-weight: 500;
        width: 100%;
    }

    .heroRight {
        width: 100%;
    }

    .innerSlider {
        width: fit-content;
        width: 100%;
    }

    #emojiOne {
        display: none;
    }

    #emojiTwo {
        display: none;
    }

    #emojiThree {
        display: none;
    }


}

@media (min-width: 1024px) {
    .innerSlider h1 {
        line-height: 1;
    }

    .innerSliderTwo h1 {
        line-height: 1;
    }
}

.innerSlider p {
    margin: 0;
}

.innerSliderTwo p {
    margin: 0;
}