.cookieWrapper {
    position: fixed;
    bottom: 50px;
    right: -370px;
    /* right: 20px; */
    max-width: 345px;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 15px 25px 22px;
    transition: right 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.cookieWrapper.show {
    right: 20px;
}

.cookieWrapper header {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

header i {
    color: #ea624c;
    font-size: 32px;
}

header h2 {
    color: #ea624c;
    font-weight: 500;
}

.cookieWrapper .data {
    margin-top: 16px;
}

.cookieWrapper .data p {
    color: #333;
    font-size: 16px;
}

.data p a {
    color: #ea624c;
    text-decoration: none;
}

.data p a:hover {
    text-decoration: underline;
}

.cookieWrapper .buttons {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttons .button {
    border: none;
    color: #fff;
    padding: 8px 0;
    border-radius: 4px;
    background: #ea624c;
    cursor: pointer;
    width: calc(100% / 2 - 10px);
    transition: all 0.2s ease;
}

.buttons #acceptBtn:hover {
    background-color: #034bf1;
}

#declineBtn {
    border: 2px solid #ea624c;
    background-color: #fff;
    color: #ea624c;
}

#declineBtn:hover {
    background-color: #ea624c;
    color: #fff;
}