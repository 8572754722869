.brandCardStyleContainer {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.brandCardStyle {
    border: 2px solid #ea624c;
    border-radius: 100%;
    object-fit: contain;
    margin: 0 auto;
    width: 130px;
    height: 130px;
    cursor: pointer;
}

.brandCardStyleImg {
    border: 2px solid #ea624c;
    border-radius: 100%;
    object-fit: contain;
    margin: 0 auto;
}


.BrandPdftooltip {
    position: relative;
    margin-top: 1em;
}

.BrandPdftooltip .tooltiptext {
    position: relative;
    visibility: hidden;
    color: #FFF;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: -35%;
    left: 2%;
    font-size: 12px;
    font-weight: 600;
    background-color: #222;
}

.BrandPdftooltip .tooltiptext i {
    color: #222;
    position: absolute;
    bottom: -42%;
    left: 45%;
    font-size: 20px;
}


.BrandPdftooltip:hover .tooltiptext {
    visibility: visible;
}