.sub-banner .banner-img {
    width: 100%;
}

.sub-banner .detail {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.32);
    padding: 10px 0 10px 0;
}

.sub-banner h4 {
    font-weight: 800;
}

.sub-banner .detail ul {
    margin-left: -2em;
}

.sub-banner .detail ul li {
    float: left;
    background: url(../icons/paging-arrow.png) no-repeat right 9px;
    padding: 0 20px 0 0;
    margin: 0 12px 0 0;
    margin-top: 5px;
    list-style: none;
}

.sub-banner .detail ul li:last-child {
    background: none;
}

.sub-banner .detail ul li a {
    color: #333333;
    text-decoration: none;
}