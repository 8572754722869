.cards_item {
    display: flex;
    padding: 1rem;
}


@media (min-width: 40rem) {
    .cards_item {
        width: 50%;
    }
}

@media (min-width: 56rem) {
    .cards_item {
        width: 33.3333%;
        cursor: pointer;
    }
}

.card_title1 {
    text-transform: capitalize;
    margin: 0px;
    font-size: 1.2rem;
    font-weight: 600;
}

.card_title2 {
    text-transform: capitalize;
    margin: 0px;
    font-size: 0.9rem;
}

.allProductcard {
    position: relative;
    background-color: white;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.card_content {
    width: 100%;
    padding: 1rem;
    /* background: rgb(255, 176, 127, 0.5); */
    z-index: 999;
}

.Related_Product_card_content {
    width: 100%;
    /* background: rgb(255, 176, 127, 0.5); */
    z-index: 999;
    padding-top: 1em;
}

.card_text {
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    font-weight: 400;
}

.made_by {
    font-weight: 400;
    font-size: 13px;
    margin-top: 35px;
    text-align: center;
}