.testimonialsTitleDescription {
    width: 50%;
}

.testimonials {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    /* font-family: 'Poppins', sans-serif; */
}

.testimonialsInner {
    width: 95%;
    height: 100%;
}


.testimonials .title h2 {
    color: #222222;
    line-height: 1.2em;
    font-weight: 900;
    font-size: 41px;
    letter-spacing: -1px;
    margin: 0;
}

.testimonials .title img {
    margin-top: -10px;
}

.testimonials .title p {
    margin: 0 0 10px;
    margin-bottom: 0;
    color: #222222;
}

.testimonials .testi .item {
    background-color: #eeecec;
    padding: 50px 30px;
    border-radius: 5px;
}

.testimonials .testi .item .profile {
    display: flex;
    padding-left: 15px;
}

.testimonials .testi .item .profile img {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    object-fit: cover
}

.testimonials .testi .item .profile .information {
    padding-left: 20px;
    margin-bottom: 15px
}

.testimonials .testi .item .profile .information .stars i {
    color: rgb(240, 210, 42);
}

.testimonials .testi .item .profile .information p {
    margin: 0px auto 0px;
    color: #222222;
    font-weight: 900;
    line-height: 1;
}

.testimonials .testi .item .profile .information span {
    color: #ea624c;
    font-weight: bold;
    margin-top: -4px;
    line-height: 1.6em;
    font-size: 14px;
}

.testimonials .testi .item>p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.6em;
    display: block;
    z-index: 2;
    color: #222222;
}

.testimonials .testi .item .icon {
    text-align: center;
}

.testimonials .testi .item .icon i {
    font-size: 32px;
    color: rgb(240, 210, 42);
}

@media (max-width: 786px) {
    .testimonialsTitleDescription {
        width: 100%;
    }
}

@media (min-width: 787px) and (max-width:1400px) {
    .testimonialsTitleDescription {
        width: 75%;
    }
}