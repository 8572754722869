.productFilterContainer {
    display: flex;
}

.productFilterContainerLeft {
    width: 20%;
}


.productFilterOuterContainerRight {
    width: 80%;
}

.productFilterContainerRight {
    display: flex;
    flex-wrap: wrap;
}

.productFilter .dropdownSelection {
    margin-bottom: 1em;
}


.productFilter .search-form {
    display: flex;
    gap: 20px;
}

.productFilter .search-form .form-group1 {
    margin-bottom: 1em;
    width: 100%;
    border-radius: 2px;
}

.productFilter .form-group {
    width: 100%;
    margin-bottom: 1em;
}

.PaginationContainer {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}


.page-link {
    color: #ea624c;
}



.active-item {
    background: #CCC;
}

/* =========================================== */

/* ================================== */

.procedures-links ul {
    width: 100%;
    list-style: none;
    padding-left: 0;
}


#procedures-links.accordion {
    width: 100%;
    max-width: 100%;
    margin: 0;
    background: #FFF;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

#procedures-links.accordion .link {
    cursor: pointer;
    display: block;
    padding: 18px 15px 18px 22px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #CCC;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background-color: rgb(8, 50, 72);
    width: 100%;
    border-radius: 0px;
}

#procedures-links.accordion li:last-child .link {
    border-bottom: 0;
}

#procedures-links.accordion li i {
    position: absolute;
    top: 16px;
    right: 12px;
    font-size: 22px;
    color: #FFFFFF;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

#procedures-links.accordion li i.fa-chevron-down {
    right: 12px;
    left: auto;
    font-size: 16px;
}

#procedures-links.accordion li.open .link {
    color: #fff;
    background-color: rgb(8, 50, 72);
}

#procedures-links.accordion li.open i {
    color: #fff;
}

#procedures-links.accordion li.open i.fa-chevron-down {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

/**
   * Submenu
   -----------------------------*/


#procedures-links .submenu {
    background: #ea624c;
    font-size: 14px;
    border-right: solid 1px #e4e4e4;
    border-left: solid 1px #e4e4e4;
    /* transition: .3s cubic-bezier(0, 1, 0.5, 1); */
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}


#procedures-links .submenu.slideUp {
    height: auto;
}

/* #procedures-links .submenu.slideDown {
    visibility: hidden;
    display: none;
    opacity: 0;
    height: 0px;
} */

#procedures-links li a {
    text-decoration: none;
}



#procedures-links .submenu li {
    border-bottom: 1px solid #E4E4E4;
    list-style: none;
}

#procedures-links .submenu a {
    display: block;
    text-decoration: none;
    color: #ffffff;
    padding: 16px 0px 16px 42px;
    font-size: 16px;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    background: url(../icons/paging-arrow.png) no-repeat 22px 24px;
}

#procedures-links .submenu li a:hover {
    background: #fff url(../icons/procedures-arrow-hover.png) no-repeat 22px 21px !important;
    color: #02adc6;
    padding: 16px 0px 16px 42px !important;
    margin: 0 -1px 0 0;
}

#procedures-links .submenu li.item-seclect a {
    background: #fff url(../icons/procedures-arrow-hover.png) no-repeat 22px 21px !important;
    color: #02adc6;
    padding: 16px 0px 16px 42px !important;
    margin: 0 -1px 0 0;
}

.productFilter span.title {
    color: #222 !important;
    width: 100%;
    display: block;
    padding: 15px 0px;
    font-size: 22px;
    font-weight: 700;
}

.btn-primary {
    background-color: #ea624c;
    border: none;
    border-radius: 2px;
}

.dataNotfoundStyle {
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: 10em;
    padding-bottom: 10em;
}

@media (max-width: 786px) {

    .procedures-links-container {
        width: 100%;
    }

    .productFilterContainer {
        flex-direction: column;
    }

    .productFilterOuterContainerRight {
        width: 100%;
    }

    .productFilterContainerRight {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .productFilterContainerLeft {
        width: 100% !important;
        display: flex;
        flex-direction: column;
    }

    .dropdownFilter {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .productSearch {
        width: 100%;
    }

    .productSearch .form-control {
        border-radius: 2px;
    }

    .dropdownFilter .dropdownSelection {
        width: 48%;
    }

}

@media (min-width: 787px) and (max-width:900px) {}

@media (min-width: 901px) and (max-width:1500px) {}