.active {
    color: #ea624c;
}

.navmenucontents li {
    text-decoration: none !important;
    color: #000;
}

.navmenucontents {
    color: #000;
    text-decoration: none !important;
}

/* .topbar {
    width: 100%;
    background-color: black;
    color: white;
} */

/* =============================top-bar======================= */

.top-bar {
    background-color: #000;
    padding: 1em 2em 0em 2em;
}

.top-bar li {
    list-style: none;
}

.top-bar li a {
    text-decoration: none;
}

.top-bar span {
    font-weight: 600;
    font-size: 0.8rem;
    font-family: sans-serif;
    color: #fff;
    /* margin: 14px 0 0 0; */
    float: left;
    text-transform: capitalize;
}

.address {
    width: 20px;
    height: 20px;
}

.addressMain {
    margin-left: 0.5em;
}

.top-bar .get-touch {
    float: right;
}

.top-bar .get-touch ul {
    float: left;
    padding-left: 1em;
}

.top-bar .get-touch li {
    float: left;
    font-size: 0.8rem;
    border-left: solid 1px #9fa3aa;
    font-family: sans-serif;
    font-weight: 600;
}

.top-bar .get-touch li :first-child(1) {
    border: 1px solid red;
}

.top-bar .get-touch li i.icon-phone4 {
    /* margin: 0px 5px 0 0; */
    float: left;
    font-size: 20px;
}

.top-bar .get-touch li i.icon-mail {
    float: left;
    font-size: 20px;
    /* margin: 0px 7px 0 0; */
}

.top-bar .get-touch li:last-child {
    border-right: none;
}

.top-bar .social-icons li:last-child {
    border-right: solid 1px #9fa3aa;
}

.top-bar .get-touch li a {
    color: #fff;
    padding: 0px 14px;
    float: left;
}

.top-bar .get-touch li i {
    font-size: 18px;
}




.logo {
    height: auto;
    width: auto;
}

.navbarMenuText {
    color: #000;
    text-decoration: none;
}

@media (max-width: 786px) {
    .top-bar .get-touch li {
        border: none !important;
    }

    .top-bar .get-touch li a {
        padding: 0px 0px;
    }


    .top-bar .get-touch ul {
        padding-left: 0;
    }

    .addressMain {
        width: 39px;
        height: 39px;
        padding-bottom: 1.5em;
        margin-left: -0.5em;
    }

    .mailStyle {
        margin-bottom: 1em;
    }

}

@media (min-width: 1024px) {
    .navbarMenuText {
        line-height: 1.75rem;
        font-size: 1.125rem;
    }
}

.bavbarBtn {
    background-color: #ea624c;
    text-decoration: none;
    border: none;
    list-style: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-weight: 600;
    color: #fff;
}



.logoImage {
    width: 7rem;
    height: 7rem;
}

.menuBtn {
    color: #000;
    width: 2.5rem;
    height: 2rem;
}

.crossBtn {
    color: #000;
    width: 2.5rem;
    height: 2rem;

}

.navShow {
    position: absolute;
    z-index: 10;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: #000;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.75rem;
    width: 100%;
    background: #fff;
    top: 80px;
    right: 0px;
    list-style-type: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.navmenucontainer {
    display: flex;
    flex-direction: row;
}