@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

img {
    width: 100%;
    display: block;
}

.card {
    border: none;
}

.img-display {
    overflow: hidden;
    border: 1px solid #CCC;
}

.img-showcase {
    display: flex;
    width: 100%;
    transition: all 0.5s ease;
}

.img-showcase img {
    min-width: 100%;
}

.img-select {
    display: flex;
}

.img-item {
    width: 25%;
    margin: 0.3rem;
}

.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3) {
    margin-right: 0;
}

.img-item:hover {
    opacity: 0.8;
}

.product-content {
    padding: 2rem 1rem;
}

.product-title {
    font-size: 2rem;
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    color: #12263a;
    margin: 1rem 0;
}

.product-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 80px;
    background: #12263a;
}

.product-link {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    background: #256eff;
    color: #fff;
    padding: 0 0.3rem;
    transition: all 0.5s ease;
}

.product-link:hover {
    opacity: 0.9;
}

.product-rating {
    color: #ffc107;
}

.product-rating span {
    font-weight: 600;
    color: #252525;
}

.product-price {
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 700;
}

.product-price span {
    font-weight: 400;
}

.last-price span {
    color: #f64749;
    text-decoration: line-through;
}

.new-price span {
    font-size: 1.4rem;
    color: #256eff;
    font-weight: 600;
}

.product-detail h2 {
    font-size: 1.8rem;
    text-transform: capitalize;
    color: #12263a;
    padding-bottom: 0.6rem;
}

.product-detail p {
    font-size: 0.9rem;
    padding: 0.3rem;
    opacity: 0.8;
}

.product-detail ul {
    margin: 1rem 0;
    font-size: 0.9rem;
}

.product-detail ul li {
    margin: 0;
    list-style: none;
    background: url(https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/checked.png) left center no-repeat;
    background-size: 18px;
    padding-left: 1.7rem;
    margin: 0.4rem 0;
    font-weight: 600;
    opacity: 0.9;
}

.product-detail ul li span {
    font-weight: 400;
}

.purchase-info {
    margin: 1.5rem 0;
}

.purchase-info input,
.purchase-info .btn {
    border: 1.5px solid #ddd;
    border-radius: 25px;
    text-align: center;
    padding: 0.45rem 0.8rem;
    outline: 0;
    margin-right: 0.2rem;
    margin-bottom: 1rem;
}

.purchase-info input {
    width: 60px;
}

.purchase-info .btn {
    cursor: pointer;
    color: #fff;
}

.purchase-info .btn:first-of-type {
    background: #256eff;
}

.purchase-info .btn:last-of-type {
    background: #f64749;
}

.purchase-info .btn:hover {
    opacity: 0.9;
}

.social-links {
    display: flex;
    align-items: center;
}

.social-links p {
    padding-top: 1em;
    padding-right: 0.2em;
}

.social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #000;
    border: 1px solid #000;
    margin: 0 0.2rem;
    border-radius: 50%;
    text-decoration: none;
    font-size: 0.8rem;
    transition: all 0.5s ease;
}

.social-links a:hover {
    background: #000;
    border-color: transparent;
    color: #fff;
}

@media screen and (min-width: 992px) {
    .card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
        border: none;
    }

    .card-wrapper {
        display: flex;
        justify-content: center;
    }

    .product-imgs {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
    }

    .product-content {
        padding-top: 0;
    }
}