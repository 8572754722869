body {
    background: #fff;
    font-family: sans-serif;
    color: #444444;
}

* {
    margin: 0px;
    padding: 0px;
}

h1 {
    font-weight: 900;
    font-family: sans-serif;
    font-size: 54px;
}

h2 {
    font-weight: 800;
    font-size: 35px;
    font-family: sans-serif;
    color: rgb(233, 171, 80);
}

span {
    color: #000;
}

h3 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 32px;
    font-family: sans-serif;
}

h4 {

    font-weight: 800;
    font-size: 28px;
    font-family: sans-serif;
}

h5 {
    font-weight: 700;
    font-size: 20px;
    font-family: sans-serif;
}

h6 {
    font-weight: 800;
    font-size: 20px;
    font-family: sans-serif;
}

p {
    font-family: sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 27px;
}

.text-gold {
    color: rgb(4, 4, 4);
}

.CustomContainer {
    padding-left: 15em;
    padding-right: 15em;
}


.marginTopBottom {
    margin-top: 4em;
    margin-bottom: 4em;
}

.paddingTopBottom {
    padding-top: 5em;
    padding-bottom: 5em;
}

.readMore {
    /* mt-4 text-blue-500 */
    margin-top: 1rem;
    color: #a75421;
    border: 0;
    background: transparent;
    font-weight: 800;
    font-size: 16px;
}

.overHide {
    overflow: hidden;
}

@media (max-width: 786px) {
    .CustomContainer {
        padding-left: 1em;
        padding-right: 1em;
        /* overflow: hidden; */
    }

    .marginTopBottom {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    .paddingTopBottom {
        padding-top: 2em;
        padding-bottom: 2em;
    }

}

@media (min-width: 787px) and (max-width:900px) {
    .CustomContainer {
        padding-left: 2em;
        padding-right: 2em;
        /* overflow: hidden; */
    }

    .marginTopBottom {
        margin-top: 3em;
        margin-bottom: 3em;
    }

    .paddingTopBottom {
        padding-top: 3em;
        padding-bottom: 3em;
    }

}

@media (min-width: 901px) and (max-width:1500px) {
    .CustomContainer {
        padding-left: 5em;
        padding-right: 5em;
        /* overflow: hidden; */
    }

    .marginTopBottom {
        margin-top: 4em;
        margin-bottom: 4em;
    }

    .paddingTopBottom {
        padding-top: 4em;
        padding-bottom: 4em;
    }

}