.primaryColor {
    background-color: #FFB07F;
    color: #FFFFFF;
}

.SecoundryColor {
    background-color: #ea624c;
    color: #FFFFFF;
}

.colorwhite {
    background-color: #FFFFFF;
    color: #000;
}

.SecoundryColorLight {
    background-color: rgb(234, 98, 76, 0.5);
}

.darkBlue {
    background-color: #071952;
    color: #FFFFFF;
}