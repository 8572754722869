.mskol {
    margin-right: 10px;
}

.contactustext {
    font-size: 16px;
}

.contactUsWrapper {
    /* relative overflow-hidden md:py-10 lg:py-[120px] md:p-10 p-1 */
    overflow: hidden;
    position: relative;
    padding: 0.25rem;
}

.innerWrapperContent {
    /* -mx-4 flex flex-wrap lg:justify-between */
    display: flex;
    margin-left: -1rem;
    margin-right: -1rem;
    justify-content: space-between;
}

.innerInnerWrapper {
    /* w-full p-5 lg:w-1/2 xl:w-6/12 */
    width: 100%;
    padding: 1.125rem;
}

.headWrapper {
    padding: 1rem;

    max-width: 570px;

}

.contactUsHeadText {
    /* text-dark md:mb-6 mb-3 text-[30px] font-bold uppercase sm:text-[40px] lg:text-[36px] xl:text-[40px] */
    color: black;
    margin-bottom: 0.75rem;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
}

.svgContainer {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    border-radius: 4px;

}

.addressContainer {
    color: #202020;
    font-size: 20px;
}

.ContactUsFrom {
    height: 700px !important;
    background-color: #FFF;
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.textArea {
    height: 200px;
}

@media (min-width: 360px) {
    .innerInnerWrapper {
        width: 100%;
    }

    .contactustext {
        font-size: 14px;
    }

    .ContactUsFrom {
        height: auto;
    }
}

.buttonSubmit {
    background-color: black;
    color: white;
    height: 3rem;
    border: none;
}

.buttonSubmit:hover {
    background-color: #000 !important;
    color: #FFF;

}

@media (min-width: 768px) {
    .contactUsWrapper {
        padding: 2.5rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .contactUsHeadText {
        margin-bottom: 1.5rem;

    }

    .ContactUsFrom {
        height: auto;
    }
}

@media (min-width: 1024px) {
    .contactUsWrapper {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .innerInnerWrapper {
        width: 50%;
    }

    .headWrapper {
        margin-bottom: 0;
    }

}

@media (min-width: 1280px) {}