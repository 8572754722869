.ProductCardContainer {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.ProductCard {
    display: inline-block;
    font-size: 14px;
    padding: 5px 100px 25px 30px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.4;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.75);
    position: relative;
    overflow: hidden;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    width: calc(48%);
    min-height: 300px;
    background: rgba(255, 255, 255, 0.15);
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4"%3E%3Cpath fill="%239C92AC" fill-opacity="0.4" d="M1 3h1v1H1V3zm2-2h1v1H3V1z"%3E%3C/path%3E%3C/svg%3E');
}

.ProductCard h1 {
    position: absolute;
    left: calc(100% - 200px);
    top: -30px;
    font-size: 80px;
    transform: rotate(-35deg);
    color: rgba(0, 0, 0, 0.07);
    text-shadow: none;
}

.ProductCard .ProductreadMore {
    position: absolute;
    color: #a75421;
    border: 0;
    background: transparent;
    font-weight: 800;
    font-size: 16px;
    bottom: 5%;
    right: 5%;
}

.ProductCard img.bg {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0px;
    left: 0px;
    z-index: -1;
}

.ProductCard h2 {
    color: rgba(0, 0, 0, 0.35);
    font-weight: 800;
    font-size: 28px;
    margin: 10px 0px 8px -2px;
    text-shadow: none;
}

.ProductCard h2 svg {
    height: 25px;
    vertical-align: -2px;
    opacity: 0.3;
}

.ProductCard .el {
    vertical-align: -2px;
    opacity: 0.3;
    margin-left: -5px;
}



::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: #123;
    border-radius: 10px;
}


@media (max-width: 786px) {
    .ProductCardContainer {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .ProductCard {
        width: calc(100%);
        margin-bottom: 20px;
    }
}