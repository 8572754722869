.mailfooter {
  background-color: #222222;
  font-size: 14px;

}

.mailfooter hr {
  color: #FFFFFF;

}

.mailfooter p {
  color: #FFFFFF;
}

.mailfooter h5 {
  color: #FFFFFF;
}

.mailfooter h6 {
  color: #FFFFFF;
}

.mailfooter .container {
  padding-top: 20px;
}



.footerhr {
  margin-top: 5px;
  width: 20%;
  height: 3px;
  background-color: #FFF;
}

.footerhrone {
  margin-top: 5px;
  width: 10%;
  height: 3px;
  background-color: #FFF;
}

.footerhrtwo {
  margin-top: 5px;
  width: 4%;
  height: 3px;
  background-color: #FFF;
  margin-left: 2px;
}

.footercontact p a {
  text-decoration: none;
  color: #FFFFFF;
}

.footertwoa {
  text-decoration: none;
  color: #FFF;
}

.footertwoa:hover {
  color: white;

}

.sundayclosedbtn {
  background-color: #D5B373;
  padding: 2px 10px;
  border-radius: 20px;
}

.footerquick {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.quickname:hover {
  margin-left: 10px;
  transition-property: all;
  /* transition-timing-function: ease; */
  transition-duration: 150ms;

}

.footerrighticon {
  margin-right: 10px;
  margin-right: 10px;
}

.footercontacttitle {
  font-weight: 600;
  line-height: 0 !important;
}

.footercontacttitle p {
  line-height: 0 !important;
}

.footercontact {
  line-height: 12px;
}

.footerphoneicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  color: #D5B373;
}

.footermailicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  color: #D5B373;
}

.footeraddicon {
  width: 80px;
  height: 30px;
  margin-right: 20px;
  color: #D5B373;
  margin-left: 5px;
}

.footercontactadress {
  line-height: 24px;
}

.d-flex .footertwo {
  color: #D5B373;
  margin-left: 5px;
}

.Footer-social-links {
  display: flex;
  align-items: center;
}

.Footer-social-links p {
  padding-top: 1em;
  padding-right: 0.5em;
}

.Footer-social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  margin: 0 0.2rem;
  border-radius: 50%;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.5s ease;
}

.Footer-social-links a:hover {
  background: #FFFFFF;
  border-color: transparent;
  color: #000;
}