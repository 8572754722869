img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    display: inline-block;
}

/* Main CSS */
.grid-wrapper>div {
    display: flex;
    justify-content: center;
    align-items: center;

}

.grid-wrapper>div>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.grid-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;
}

.grid-wrapper .wide {
    grid-column: span 2;
}

.grid-wrapper .tall {
    grid-row: span 2;
}

.grid-wrapper .big {
    grid-column: span 2;
    grid-row: span 2;
}



img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    display: inline-block;
}

/* Main CSS */
.grid-wrapper>div {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 2px rgb(196, 192, 192);
    border-radius: 5px;
}

.grid-wrapper>div>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.grid-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;
}

.grid-wrapper .wide {
    grid-column: span 2;
}

.grid-wrapper .tall {
    grid-row: span 2;
}

.grid-wrapper .big {
    grid-column: span 2;
    grid-row: span 2;
}

.grid-wrapper .popularItemCard {
    position: relative;
    cursor: pointer;
}

.content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: all 0.3s ease;
    border-radius: 5px;
}

.content1 {
    background-color: rgba(18, 174, 224, 0.7);
}

.content2 {
    background-color: rgba(245, 40, 145, 0.7);
}

.content3 {
    background-color: rgba(18, 174, 224, 0.7);
}

.content4 {
    background-color: rgba(27, 0, 240, 0.5);
}

.content5 {
    background-color: rgba(240, 162, 0, 0.5);
}

.content6 {
    background-color: rgba(232, 0, 240, 0.5);
}

.content7 {
    background-color: rgba(240, 27, 0, 0.5);
}

.content8 {
    background-color: rgba(0, 0, 0, 0.5);
}

.content9 {
    background-color: rgba(198, 62, 141, 0.7);
}

.content:first-child(1) {
    border: 1px solid red;
}

.content * {
    /* transform: translateY(-70%); */
    transform: translateY(0);
    transition: all 0.3s ease;
    opacity: 0;
}

.grid-wrapper .popularItemCard:hover .content {
    opacity: 0;
}

.grid-wrapper .popularItemCard:hover .content * {
    opacity: 0;
    transform: translateY(-70%);
}