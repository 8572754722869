.brandSliderStyle {
    margin-top: 2em;
}

.brandSliderStyleImg {
    border: 2px solid #ea624c;
    border-radius: 100%;
    width: 75%;
    height: 75%;
    object-fit: contain;
    margin: 0 auto;
}

.brandSliderStyle {
    cursor: pointer;
}

.Pdftooltip {
    position: relative;
    padding: 2em 0;
}

.Pdftooltip .tooltiptext {
    position: relative;
    visibility: hidden;
    color: #FFF;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    top: -2%;
    left: 20%;
    font-size: 12px;
    font-weight: 600;
    background-color: #222;
}

.Pdftooltip .tooltiptext i {
    color: #222;
    position: absolute;
    bottom: -45%;
    left: 45%;
    font-size: 20px;
}

.Pdftooltip:hover .tooltiptext {
    visibility: visible;
}