/* Subscribe Box */
#subscribe-css {
    position: relative;
    background-image: url(../images/home/News_Letter.jpg);
    border-top: 4px solid #eee;
    padding-top: 20px;
    padding-bottom: 20px;
}

.subscribe-wrapper {
    color: #415471;
    font-size: 16px;
    line-height: normal;
    margin: 0;
    text-align: right;
    text-transform: none;
    font-weight: 400;
    width: 100%
}

.subscribe-form {
    clear: both;
    display: block;
    overflow: hidden
}

form.subscribe-form {
    clear: both;
    display: block;
    margin: 0;
    width: auto;
    overflow: hidden
}

.subscribe-css-email-field {
    background: #FFFFFF;
    color: #ccc;
    margin: 10px 0;
    padding: 15px 20px;
    width: 35%;
    border: 0
}

.subscribe-css-email-button {
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 700;
    padding: 14px 30px;
    margin-left: 15px;
    text-transform: none;
    font-size: 16px;
    border: 0;
    border-radius: 3px;
    transition: all .6s;
    background: #ea624c;
}

.subscribe-css-email-button:hover {
    background: #FFFFFF;
    color: #000000;
}

#subscribe-css p.subscribe-note {
    margin: 16px;
    text-align: right;
    color: #415471;
    font-size: 180%;
    font-weight: 400;
    line-height: normal;
}

#subscribe-css p.subscribe-note span {
    position: relative;
    overflow: hidden;
    font-weight: 700;
    transition: all .5s;
    color: #000000;
}

#subscribe-css .itatu {
    font-style: italic;
    color: #415471;
}

#subscribe-css p.subscribe-note span.itatu:before,
#subscribe-css p.subscribe-note span.itatu:after {
    display: none
}

#subscribe-css p.subscribe-note span:before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 3px;
    margin: 10px 0 0;
    background: rgba(255, 255, 255, .1);
    transition: all .5s
}

#subscribe-css:hover p.subscribe-note span:before {
    width: 100%;
}



@media (max-width: 786px) {
    .subscribe-wrapper {
        color: #415471;
        font-size: 16px;
        line-height: normal;
        margin: 0;
        text-align: center;
        text-transform: none;
        font-weight: 400;
        width: 100%
    }

    #subscribe-css p.subscribe-note {
        margin: 16px;
        text-align: center;
        color: #415471;
        font-size: 180%;
        font-weight: 400;
        line-height: normal;
    }

    #subscribe-css {
        background-size: cover;
    }

    .subscribe-css-email-field {
        background: #FFFFFF;
        color: #ccc;
        margin: 10px 0;
        padding: 15px 20px;
        width: 50%;
        border: 0
    }
}